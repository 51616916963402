@media only screen and (min-width: 780px) {
  div.container-cart {
    display: grid;
    flex-direction: column;
    grid-template-areas:
      "cart-header cart-header"
      "cart-list cart-checkout";
    grid-template-columns: auto 300px;
    grid-template-rows: auto;
  }
  section#cart-list article > img {
    width: 10%;
    object-fit: cover;
  }

  div.itens_complementares {
    margin-left: 5%;
    margin-bottom: 8%;
    border-bottom: 3px solid #999;
  }
}

@media only screen and (max-width: 779px) {
  div.container-cart {
    display: grid;
    flex-direction: row;
    grid-template-areas:
      "cart-header"
      "cart-list"
      "cart-checkout";
    grid-template-columns: auto;
    grid-template-rows: auto;
  }
  section#cart-list article > img {
    width: 20%;
    object-fit: cover;
  }

  div.itens_complementares {
    margin-left: 5%;
    margin-bottom: 5%;
    border-bottom: 2px solid #999;
  }
}

div.itens_complementares > article {
  background-color: #eee !important;
}

section#cart-header {
  grid-area: cart-header;
  color: #fff;
  padding: 30px;
  display: flex;
  align-items: left;
  justify-content: space-between;
  flex-direction: row;
  background-color: #111;
  opacity: 0.8;
  border-bottom: 1px solid #006dff;
}

section#cart-list {
  grid-area: cart-list;
  margin: 15px auto;
  padding: 0 15px 0 15px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}

section#cart-checkout {
  color: #777;
  grid-area: cart-checkout;
  background-color: #111;
  background-blend-mode: multiply;
  border-left: 1px solid #006dff;
  border-right: 1px solid #006dff;
  margin: 0 15px 0 15px;
  padding: 15px;
}

section#cart-checkout button {
  color: #777;
  font-size: 1em;
}

section#cart-list article {
  position: relative;
  display: flex;
  align-items: left;
  justify-content: space-between;
  background: #fff;
  border: 1px solid transparent;
  border-top: 1px solid #ddd;
  min-width: 100%;
  overflow: visible;
}

section#cart-list article:hover {
  border: 1px solid #7159c1;
}

section#cart-list article header {
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-grow: 1;
}

section#cart-list article header .prod-info {
  display: flex;
  flex-direction: column;
}

section#cart-list article header .prod-info span {
  font-size: 13px;
}

section#cart-list article header .prod-info span.prod-marca {
  font-size: 9px;
  color: #666;
  margin-top: 3px;
}

section#cart-list article header .prod-discr {
  display: flex;
  justify-content: flex-end;
  flex-grow: 1;
  text-align: right;
  margin-right: 20px;
  flex-direction: column;
}

section#cart-list article header img {
  padding: 5px;
  cursor: pointer;
}

section#cart-list article header img:hover {
  background-color: #ddd;
}

section#cart-list article footer {
  padding: 20px;
}

section#cart-list article footer .item-actions {
  margin-bottom: 10px;
}

section#cart-list article footer .item-actions button {
  background: transparent;
  border: 0;
  cursor: pointer;
}

section#cart-list article footer .item-actions img {
  height: 20px;
  margin-right: 10px;
}

section#cart-list article footer p {
  font-size: 13px;
  margin-top: 2px;
  line-height: 18px;
}

section#cart-list article footer p span {
  color: #7159c1;
  display: block;
}

section#cart-list article .mask {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  background-color: rgba(0, 134, 195, 0.8);
  width: 100%;
  height: 100%;
  z-index: 2;
  overflow-y: auto;
  overflow-x: hidden;
  color: #fff;
}

section#cart-list article .mask ul#mnuBit,
section#cart-list article .mask div#mnuQt {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  overflow-y: auto;
  padding: 20px;
}

.bit_item {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  border-top: 1px solid #fff;
}

img.backToList {
  cursor: pointer;
  height: 60px;
  width: 60px;
  margin: 10px auto;
  padding: 10px 10px 10px 10px;
}

img.backToList:hover {
  background-color: #ddd;
}

section .cart-empty {
  margin: 40vh auto;
  color: #bbb;
  display: flex;
  text-align: center;
  flex-direction: column;
  justify-content: center;
}

section .cart-empty a {
  display: flex;
  justify-content: center;
}

.bit_item {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  border-top: 1px solid #fff;
}

.bit_item:hover,
.qt_item:hover,
.bt_alpha:hover {
  background-color: rgba(255, 255, 255, 0.3);
}

.qt_item::placeholder {
  color: white;
  font-size: 0.8em;
}

.bt_checkout {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #eee;
  border: none;
  margin: 10px 0;
  height: 50px !important;
  padding: 10px;
  text-align: center;
  color: #666;
  cursor: pointer;
  width: 100%;
}

.bt_checkout:hover {
  background-color: #ddd;
}
.payment {
  border: 1px solid #666;
  font-weight: 600;
}

.qt_item {
  border-top: 1px solid #fff;
  border-bottom: 1px solid #fff;
}

.icon32 {
  width: 32px;
}

.icon64 {
  width: 64px;
}

.icon24 {
  width: 24px;
}

section#cart-list Loader {
  margin: 10% auto;
}

.resumo-modal {
  display: flex;
  justify-content: space-between;
}

.resumo-modal div {
  width: 100%;
}
.resumo-modal + div > form {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.resumo-modal + div form > input {
  width: 100%;
  padding: 10px;
  margin-top: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.resumo-modal + div form > button {
  margin-top: 10px;
}
